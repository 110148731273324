<template>
  <action-dispatcher
    action="quiz/getDetail"
    :parameters="{ id }"
    class="component-quiz"
  >
    <form class="form-survey" id="form-survey" @submit.prevent="submitSurvey">
      <ol class="survey-questions">
        <div class="category-container">
          <!-- cada pergunta é um field wrapper -->
          <div
            class="field-wrapper"
            v-for="(field, index) in quiz.questions"
            :key="index"
          >
            <!-- {{ field.errors }} -->

            <div v-if="field.image">
              <img loading="lazy" :src="field.image" style="width: 100%" />
            </div>
            <div v-else class="question-background">
              <label class="label-inline">{{ field.title }}</label>
            </div>

            <div class="form-field-body fields-survey-form">
              <ul
                :class="{
                  'd-flex': field.question_options[0].image,
                  'flex-wrap': field.question_options[0].image
                }"
              >
                <li
                  :id="index"
                  v-for="(option, opindex) in field.question_options"
                  :key="opindex"
                  :class="{ 'li-image': option.image }"
                >
                  <label
                    :for="`${index}_${opindex}`"
                    :class="{
                      'label-img': option.image,
                      selected: field.answer,
                      'li-selected-wa':
                        field.answer == option.id &&
                        !option.right_answer &&
                        quiz.survey_type == 'knowledge',
                      'li-selected':
                        field.answer &&
                        option.right_answer &&
                        quiz.survey_type == 'knowledge'
                    }"
                  >
                    <input
                      v-model="field.answer"
                      :name="index"
                      type="radio"
                      required
                      :value="option.id"
                      class="wa bg-white"
                      :class="{ 'input-img': option.image }"
                      :id="`${index}_${opindex}`"
                    />
                    {{ !option.image ? option.title : "" }}

                    <div v-if="option.image" class="img-container">
                      <div style="position:relative">
                        <img
                          :src="option.image"
                          style="border-radius: 12px; width: 100%; height: 100%; max-width: 220px; max-height:160px"
                        />
                        <div class="img-selected" style=""></div>
                      </div>
                      <p
                        class="mb-0 image-description"
                        style="position:absolute; bottom:11px; color: #FFFFFF;font-weight: bold; text-align:center; left: -50%; font-size:1em;
                        right: -50%;"
                      >
                        {{ option.title }}
                      </p>
                    </div>
                  </label>
                </li>
              </ul>

              <hr />
            </div>
          </div>
        </div>
      </ol>

      <div v-if="survey_result">
        <div v-if="quiz.survey_type == 'personality'">
          <div v-if="survey_result.image">
            <img
              loading="lazy"
              :src="survey_result.image"
              id="result"
              class="my-4"
              style="width: 100%"
            />
            <div class="d-flex justify-content-end">
              <a :href="survey_result.image" target="_blank">
                <div class="download-result btn">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M18.1075 9.89289C18.498 10.2834 18.498 10.9166 18.1075 11.3071L12.7075 16.7071C12.317 17.0976 11.6838 17.0976 11.2933 16.7071L5.89328 11.3071C5.50276 10.9166 5.50276 10.2834 5.89328 9.89289C6.28381 9.50237 6.91697 9.50237 7.3075 9.89289L11.0004 13.5858L11.0004 4C11.0004 3.44771 11.4481 3 12.0004 3C12.5527 3 13.0004 3.44771 13.0004 4L13.0004 13.5858L16.6933 9.89289C17.0838 9.50237 17.717 9.50237 18.1075 9.89289Z"
                      fill="#294966"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4 20C4 19.4477 4.44772 19 5 19H19C19.5523 19 20 19.4477 20 20C20 20.5523 19.5523 21 19 21H5C4.44772 21 4 20.5523 4 20Z"
                      fill="#294966"
                    /></svg
                  >Baixar Imagem
                </div>
              </a>
              <button
                class="remake-result show-form-result btn btn-primary"
                type="button"
                @click="reset"
              >
                <svg
                  width="20"
                  height="20"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="redo"
                  class="svg-inline--fa fa-redo fa-w-16"
                  role="img"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="#ffffff"
                    d="M500.33 0h-47.41a12 12 0 0 0-12 12.57l4 82.76A247.42 247.42 0 0 0 256 8C119.34 8 7.9 119.53 8 256.19 8.1 393.07 119.1 504 256 504a247.1 247.1 0 0 0 166.18-63.91 12 12 0 0 0 .48-17.43l-34-34a12 12 0 0 0-16.38-.55A176 176 0 1 1 402.1 157.8l-101.53-4.87a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12h200.33a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12z"
                  ></path></svg
                >Refazer teste
              </button>
            </div>
          </div>
          <div v-else>
            <div id="survey-result-img" style="position: relative">
              <img
                loading="lazy"
                src="@/assets/images/standart_result.jpg"
                id="result"
                crossorigin="anonymous"
                class="my-4"
                style="width: 100%; height: auto"
              />
              <div
                class="survey-result"
                style="
                  display: flex;
                  flex-direction: column;
                  position: absolute;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  left: 0;
                  align-items: center;
                  justify-content: center;
                "
              >
                <div v-if="survey_result.title">
                  <div class="survey-result">
                    <h4 style="color: #ffffff; margin-bottom: 0">
                      {{ survey_result.title }}
                    </h4>
                    <hr
                      style="border: 1px solid; width: 100%; min-width: 125px"
                    />
                  </div>
                </div>
                <div v-if="survey_result.text">
                  {{ survey_result.text }}
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <div class="download-result btn" @click="printDiv">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.1075 9.89289C18.498 10.2834 18.498 10.9166 18.1075 11.3071L12.7075 16.7071C12.317 17.0976 11.6838 17.0976 11.2933 16.7071L5.89328 11.3071C5.50276 10.9166 5.50276 10.2834 5.89328 9.89289C6.28381 9.50237 6.91697 9.50237 7.3075 9.89289L11.0004 13.5858L11.0004 4C11.0004 3.44771 11.4481 3 12.0004 3C12.5527 3 13.0004 3.44771 13.0004 4L13.0004 13.5858L16.6933 9.89289C17.0838 9.50237 17.717 9.50237 18.1075 9.89289Z"
                    fill="#294966"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4 20C4 19.4477 4.44772 19 5 19H19C19.5523 19 20 19.4477 20 20C20 20.5523 19.5523 21 19 21H5C4.44772 21 4 20.5523 4 20Z"
                    fill="#294966"
                  /></svg
                >Baixar Imagem
              </div>
              <button
                class="remake-result show-form-result btn btn-primary"
                type="button"
                @click="reset"
              >
                <svg
                  width="20"
                  height="20"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="redo"
                  class="svg-inline--fa fa-redo fa-w-16"
                  role="img"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="#ffffff"
                    d="M500.33 0h-47.41a12 12 0 0 0-12 12.57l4 82.76A247.42 247.42 0 0 0 256 8C119.34 8 7.9 119.53 8 256.19 8.1 393.07 119.1 504 256 504a247.1 247.1 0 0 0 166.18-63.91 12 12 0 0 0 .48-17.43l-34-34a12 12 0 0 0-16.38-.55A176 176 0 1 1 402.1 157.8l-101.53-4.87a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12h200.33a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12z"
                  ></path></svg
                >Refazer teste
              </button>
            </div>
          </div>
        </div>

        <div v-if="quiz.survey_type == 'knowledge'">
          <div id="survey-result-img" style="position:relative">
            <!-- <div v-if="standard_result.image">
              <img loading=lazy :src="standart_result.image" id='result' class="my-4" crossorigin="anonymous" style="width:100%; height: auto">
            </div> -->
            <div>
              <img
                loading="lazy"
                src="@/assets/images/standart_result.jpg"
                id="result"
                class="my-4"
                crossorigin="anonymous"
                style="width:100%; height: auto"
              />
            </div>
            <div
              style="display: flex; flex-direction: column; position: absolute; top: 0; width: 100%; height: 100%; left: 0; align-items: center;justify-content: center"
            >
              <!-- <div v-if="standard_result.text">
                {{ standard_result.text }}
              </div> -->
              <div>
                <div class="survey-result">
                  <h4 class="title-result">Parabéns!</h4>
                  <hr style="border: 1px solid; width:100px " />
                  <p class="result">
                    Você acertou {{ survey_result }} do Quizz!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <div
              class="download-result btn"
              id="download_canvas_result"
              @click="printDiv"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18.1075 9.89289C18.498 10.2834 18.498 10.9166 18.1075 11.3071L12.7075 16.7071C12.317 17.0976 11.6838 17.0976 11.2933 16.7071L5.89328 11.3071C5.50276 10.9166 5.50276 10.2834 5.89328 9.89289C6.28381 9.50237 6.91697 9.50237 7.3075 9.89289L11.0004 13.5858L11.0004 4C11.0004 3.44771 11.4481 3 12.0004 3C12.5527 3 13.0004 3.44771 13.0004 4L13.0004 13.5858L16.6933 9.89289C17.0838 9.50237 17.717 9.50237 18.1075 9.89289Z"
                  fill="#294966"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4 20C4 19.4477 4.44772 19 5 19H19C19.5523 19 20 19.4477 20 20C20 20.5523 19.5523 21 19 21H5C4.44772 21 4 20.5523 4 20Z"
                  fill="#294966"
                /></svg
              >Baixar Imagem
            </div>
            <button
              @click="reset"
              class="remake-result show-form-result btn btn-primary"
              type="button"
            >
              <svg
                width="20"
                height="20"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="redo"
                class="svg-inline--fa fa-redo fa-w-16"
                role="img"
                viewBox="0 0 512 512"
              >
                <path
                  fill="#ffffff"
                  d="M500.33 0h-47.41a12 12 0 0 0-12 12.57l4 82.76A247.42 247.42 0 0 0 256 8C119.34 8 7.9 119.53 8 256.19 8.1 393.07 119.1 504 256 504a247.1 247.1 0 0 0 166.18-63.91 12 12 0 0 0 .48-17.43l-34-34a12 12 0 0 0-16.38-.55A176 176 0 1 1 402.1 157.8l-101.53-4.87a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12h200.33a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12z"
                ></path></svg
              >Refazer teste
            </button>
          </div>
        </div>

        <div v-if="quiz.survey_type == 'poll'">
          <div class="my-4">
            <h3 style="text-align: left">
              <strong>Resultado parcial da votação</strong>
            </h3>
            <div v-for="(field, index) in quiz.questions" :key="index">
              <h4 style="text-align: left">
                <strong>{{ field.title }}</strong>
              </h4>
              <ol>
                {%for opcao in pergunta%}
                <div
                  :id="index"
                  v-for="(option, opindex) in field.question_options"
                  :key="opindex"
                >
                  <li>
                    <h5>
                      {{ option.title }} - {{ option.votes }} voto{{
                        opcao.votes | pluralize
                      }}
                    </h5>
                  </li>
                </div>
              </ol>
            </div>
            {%for pergunta in resultado%} {% endfor %}
          </div>
        </div>
      </div>
      <div v-else>
        <button
          class="show-form-result align-items-center btn btn-primary"
          type="submit"
          style="margin-left: auto"
        >
          Enviar respostas
        </button>
      </div>
    </form>
  </action-dispatcher>
</template>
<script>
import { mapState } from "vuex";
import html2canvas from "html2canvas";

export default {
  props: {
    id: {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      survey_result: false
    };
  },
  computed: {
    ...mapState("quiz", ["quiz"])
  },
  methods: {
    reset() {
      this.survey_result = null;
      for (let i in this.quiz.questions) {
        this.quiz.questions[i].answer = null;
      }
    },
    submitSurvey() {
      if (this.quiz.survey_type == "personality") {
        let answer_list = [];

        for (let question in this.quiz.questions) {
          for (let option in this.quiz.questions[question].question_options) {
            if (
              this.quiz.questions[question].question_options[option].id ==
              this.quiz.questions[question].answer
            ) {
              answer_list.push(
                this.quiz.questions[question].question_options[option]
                  .survey_result.id
              );
            }
          }
        }
        this.survey_result = this.getSurveyResult(this.mode(answer_list));
      } else if (this.quiz.survey_type == "knowledge") {
        let count_correct_answer = 0;

        for (let question in this.quiz.questions) {
          // answer_list.push(this.quiz.questions[question].answer);
          for (let option in this.quiz.questions[question].question_options) {
            if (
              this.quiz.questions[question].question_options[option].id ==
              this.quiz.questions[question].answer
            ) {
              if (
                this.quiz.questions[question].question_options[option]
                  .right_answer
              ) {
                count_correct_answer++;
              }
            }
          }
        }

        let result = parseInt(
          Math.round(count_correct_answer / this.quiz.questions.length, 2) * 100
        );
        this.survey_result = `${result}%`;
      }
    },
    getSurveyResult(id) {
      for (let i in this.quiz.questions) {
        for (let j in this.quiz.questions[i].question_options) {
          if (
            this.quiz.questions[i].question_options[j].survey_result.id == id
          ) {
            return this.quiz.questions[i].question_options[j].survey_result;
          }
        }
      }
    },
    mode(a) {
      a = a.slice().sort((x, y) => x - y);

      var bestStreak = 1;
      var bestElem = a[0];
      var currentStreak = 1;
      var currentElem = a[0];

      for (let i = 1; i < a.length; i++) {
        if (a[i - 1] !== a[i]) {
          if (currentStreak > bestStreak) {
            bestStreak = currentStreak;
            bestElem = currentElem;
          }

          currentStreak = 0;
          currentElem = a[i];
        }

        currentStreak++;
      }

      return currentStreak > bestStreak ? currentElem : bestElem;
    },
    printDiv() {
      var div = document.getElementById("survey-result-img");
      html2canvas(div, {
        letterRendering: 1,
        allowTaint: false,
        useCORS: true
      }).then(function(canvas) {
        var myImage = canvas.toDataURL();
        var link = document.createElement("a");
        link.download = "resultado_do_teste.png";
        link.href = myImage + "#survey-result-img";
        document.body.appendChild(link);
        link.click();
        // location.href = "#survey-result-img";
        document.body.removeChild(link);
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.component-quiz {
  .survey-questions {
    padding-inline-start: 0px;

    .question-background {
      min-height: 189px;
      // background: url("@/assets/images/question-background.png");
      background: #294966;
      background-size: cover;
      background-position: bottom;
      background-repeat: no-repeat;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      label {
        font-family: "Exo 2";
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 150%;
        color: #ffffff;
        text-align: center;
      }
    }
  }

  .survey-result {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    & > h4 {
      font-family: "Exo 2";
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 130%;
      display: flex;
      align-items: center;
      text-align: center;
      color: #fafafa;
      margin-bottom: 0px;
    }
    & > hr {
      color: #9cd33a;
      background: #9cd33a;
      width: 200px;
      margin-bottom: 5px;
      margin-top: 0px;
    }
    & > p {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      text-align: center;
      color: #fafafa;
      max-width: 200px;
    }
  }

  .fields-survey-form {
    margin-top: 20px;
    hr {
      margin: 24px 0px;
      background: #c8d6e5;
      opacity: 0.3;
    }
    ul {
      list-style: none;
      padding-left: inherit;
      justify-content: space-evenly;
      li {
        &:hover {
          background-color: #5ce5bc36;
        }
        display: flex;
        align-items: center;
        background: #fff;
        border-radius: 12px;
        margin-bottom: 8px;
        label {
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 0px;
          font-family: "DM Sans";
          font-size: 14px;
          line-height: 150%;
          min-height: 48px;
        }
        .label-img {
          position: relative;
          max-height: 100%;
          justify-content: center;
          padding: 10px 0px;
          @media screen and (max-width: 400px) {
            padding: 7px 0px;
          }
        }
        input[type="radio"] {
          display: flex;
          align-items: center;
          width: 24px;
          height: 24px;
          margin-right: 8px;
          cursor: pointer;
          font-size: 17px;
          margin-left: 8px;
          visibility: hidden;
        }
        .bg-red:after {
          background-color: #294966;
        }
        .bg-white:after {
          background-color: #fff;
        }
        input[type="radio"]:after {
          content: " ";
          display: flex;
          align-items: center;
          display: inline-block;
          color: #fff;
          width: 24px;
          height: 24px;
          border: 1px solid #c8d6e5;
          box-sizing: border-box;
          border-radius: 8px;
          visibility: visible;
        }
        input[type="radio"]:checked:after {
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          background-color: #5ce5bd;
          content: "\2714";
        }
        .input-img {
          position: absolute;
          top: 18px;
          right: 18px;
          z-index: 1;
          @media screen and (max-width: 400px) {
            top: 10px;
            right: 5px;
          }
        }
      }
      .selected {
        font-weight: bold;
      }
      .li-image {
        flex: 0 0 32%;
        max-width: 32%;
        margin-right: 10px;
        margin-bottom: 5px;
        &:nth-child(3n) {
          margin-right: 0px;
        }
        .img-container {
          padding: 0px 10px;
        }
        @media screen and (max-width: 991px) {
          flex: 0 0 45%;
          max-width: 240px;
          margin-right: 5px;
          &:nth-child(3n) {
            margin-right: 5px;
          }
          &:nth-child(2n) {
            margin-right: 0px;
          }
        }
        @media screen and (max-width: 400px) {
          .img-container {
            padding: 0px 7px;
          }
        }
      }
      .img-selected {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.3) 100%
        );
        border-radius: 12px;
        display: block;
      }
      .li-selected-wa {
        background-color: rgb(238, 51, 34);
        color: white;
        border-radius: 12px;

        input[type="radio"]:checked:after {
          background-color: transparent;
          content: "\2718";
        }
      }
      .li-selected {
        border-radius: 12px;
        background: #5ce5bc36;
        .img-selected {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          background: #5ce5bd;
          opacity: 0.2;
          border-radius: 12px;
          display: block;
        }
      }
      .li-selected:hover {
        background-color: #5ce5bd;
      }
    }
  }

  .show-form-result {
    display: flex;
    justify-content: flex-end;
    // margin-left: auto;
  }
  .remake-result {
    align-items: center;
    svg {
      margin-right: 7px;
    }
  }
  .download-result {
    align-items: center;
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    border: 1px solid #294966;
    box-sizing: border-box;
    border-radius: 16px;
    margin-right: 16px;
    svg {
      margin-right: 7px;
    }
  }
}
</style>
