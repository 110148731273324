<template>
  <div class="lesson-search">
    <div
      v-if="!showing"
      class="d-flex justify-content-between align-items-center"
    >
      <h5 class="font-weight-bold mb-0">
        Aulas
      </h5>
      <button @click="showing = true">
        <i class="icon-search" />
      </button>
    </div>

    <div
      v-if="showing"
      class="d-flex justify-content-between align-items-center"
    >
      <input
        v-model="text"
        type="text"
        class="form-control"
        placeholder="Pesquisar aula"
      />
      <button @click="close">
        <i class="icon-x" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: "",
      showing: false
    };
  },
  watch: {
    text(value) {
      this.$emit("input", value);
    }
  },
  methods: {
    close() {
      this.showing = false;
      this.text = "";
    }
  }
};
</script>

<style lang="scss" scoped>
.lesson-search {
  button {
    background: none;
    border: none;
    color: #8395a7;
    font-size: 18px;
  }

  h5 {
    line-height: 38px;
  }
}
</style>
