<template>
  <div class="lesson-audio-player">
    <div class="d-flex flex-column align-items-between justify-content-between">
      <div v-if="lesson.picture">
        <img
          :src="lesson.picture"
          :alt="lesson.name"
          width="100%"
          height="50%"
        />
      </div>
      <audio id="Audio-Player" class="w-100 mt-n5" controls playsinline />
    </div>
  </div>
</template>
<script>
import Plyr from "plyr";
import "plyr/dist/plyr.css";
import get from "lodash/get";
import isMobile from "ismobilejs";
export default {
  props: {
    lesson: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      player: null,
      playerOptions: {
        autoplay: false,
        invertTime: false,
        i18n: {
          speed: "Velocidade",
          quality: "Qualidade",
          qualityLabel: {
            0: "Auto"
          }
        },
        speed: {
          selected: 1,
          options: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 3]
        }
      }
    };
  },
  mounted() {
    const audio = document.getElementById("Audio-Player");
    let source = document.createElement("source");
    source.src = this.lesson.file;

    //discovering type of source
    if (this.lesson.file.includes(".mp3")) {
      source.type = "audio/mpeg";
    } else {
      if (this.lesson.file.includes(".ogg")) {
        source.type = "audio/ogg";
      } else if (this.lesson.file.includes(".wav")) {
        source.type = "audio/wav";
      }
    }
    //end of if's

    audio.appendChild(source);
    let controls = [
      "play-large",
      "play",
      "progress",
      "current-time",
      "mute",
      "volume",
      "captions",
      "settings",
      "pip",
      "airplay"
    ];

    if (!isMobile(window.navigator).any) {
      controls = ["rewind", "fast-forward", ...controls, "fullscreen"];
    }

    this.playerOptions.controls = controls;
    this.initPlayer();
  },

  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    initPlayer() {
      const audio = document.getElementById("Audio-Player");

      this.player = new Plyr(audio, this.playerOptions);

      var firstPlay = true;
      this.player.on("play", () => {
        if (firstPlay) {
          setTimeout(() => {
            this.player.currentTime = get(
              this.lesson,
              "progress.stopped_at",
              0
            );
          }, 1);
          firstPlay = false;
        }
      });

      const saveCurrentPosition = () => {
        this.saveDuration({
          finished: this.player.currentTime === this.duration,
          duration: this.player.currentTime
        });
      };

      this.interval = setInterval(() => {
        if (this.player.playing) {
          saveCurrentPosition();
        }
      }, 20 * 1000);

      this.player.on("pause", saveCurrentPosition);

      this.player.on("ended", event => {
        setTimeout(() => {
          this.saveDuration({ finished: true, duration: 0 });
        }, 200);
      });
    },
    saveDuration({ finished, duration }) {
      this.$store.dispatch("courses/setProgress", {
        lesson: this.lesson.id,
        stopped_at: parseInt(duration),
        finished_at: finished
      });
    }
  }
};
</script>
<style scopped lang="scss">
.lesson-audio-player {
  background-color: #fff;
  border: 1px solid #c1c1c1;
  border-radius: 24px;
  --plyr-audio-controls-background: transparent;
}
</style>
