<template>
  <div class="lesson-video-player">
    <video
      v-if="lesson.stream_url.includes('m3u8')"
      controls
      playsinline
      crossorigin
      :id="id"
      key="complex"
    ></video>
    <div
      class="embed-responsive embed-responsive-16by9 mb-3"
      id="lesson-video-player"
      v-else
    >
      <iframe
        class="embed-responsive-item"
        :src="lesson.stream_url"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>
import Hls from "hls.js";
import Plyr from "plyr";
import "plyr/dist/plyr.css";
import get from "lodash/get";
import isMobile from "ismobilejs";

export default {
  props: {
    lesson: {
      type: Object,
      default: () => ({})
    },
    id: {
      type: String,
      default: "lesson-video-player"
    }
  },
  data() {
    return {
      player: null,
      simple: false,
      isMobile: isMobile(window.navigator).any
    };
  },
  mounted() {
    const video = document.getElementById(this.id);

    this.player = new Plyr(video, {
      autoplay: false,
      inline: false,
      i18n: {
        speed: "Velocidade"
      }
    });

    if (video.canPlayType("application/vnd.apple.mpegURL")) {
      let source = document.createElement("source");
      source.src = this.lesson.stream_url;
      source.type = "application/vnd.apple.mpegURL";
      video.appendChild(source);
    } else if (Hls.isSupported()) {
      var config = {
        xhrSetup: function(xhr, url) {
          xhr.withCredentials = false;
        },
        autoStartLoad: false
      };

      const hls = new Hls(config);
      hls.loadSource(this.lesson.stream_url);
      hls.attachMedia(video);

      hls.on(Hls.Events.MANIFEST_PARSED, function() {
        hls.startLoad();
      });
    }

    var firstPlay = true;
    this.player.on("play", () => {
      if (firstPlay) {
        setTimeout(() => {
          this.player.currentTime = get(this.lesson, "progress.stopped_at", 0);
        }, 1);
        firstPlay = false;
      }
    });

    const saveCurrentPosition = () => {
      this.saveDuration({
        finished: this.player.currentTime === this.duration,
        duration: this.player.currentTime
      });
    };

    this.interval = setInterval(() => {
      if (this.player.playing) {
        saveCurrentPosition();
      }
    }, 20 * 1000);

    this.player.on("pause", saveCurrentPosition);

    this.player.on("ended", event => {
      setTimeout(() => {
        this.saveDuration({ finished: true, duration: 0 });
      }, 200);
    });
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    saveDuration({ finished, duration }) {
      this.$store.dispatch("courses/setProgress", {
        lesson: this.lesson.id,
        stopped_at: parseInt(duration),
        finished_at: finished
      });
    }
  }
};
</script>
