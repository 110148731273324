<template>
  <aside class="lesson-lessons-list bg-white p-4 shadow">
    <search v-model="searchTerm" class="mb-4" />

    <div class="desktop-scroll">
      <router-link
        v-for="(lesson, i) in lessons"
        :key="i"
        class="card text-decoration-none text-dark mb-2"
        :to="{ name: 'lesson', params: { lesson: lesson.id } }"
      >
        <div class="card-body d-flex align-items-center">
          <span class="flex-fill pr-2">
            {{ lesson.name }}
            <br />
            <small class="text-secondary">{{ duration(lesson) }}</small>
          </span>

          <div
            v-if="lesson.progress && lesson.progress.finished_at"
            class="circle check"
          >
            <img src="https://img.icons8.com/metro/16/FFFFFF/checkmark.png" />
          </div>

          <template v-else>
            <div class="circle play-current">
              <img src="https://img.icons8.com/metro/16/EF4D57/play.png" />
            </div>
            <div class="circle play-next">
              <img src="https://img.icons8.com/metro/16/C8D6E5/play.png" />
            </div>
          </template>
        </div>
      </router-link>
    </div>
  </aside>
</template>

<script>
import Search from "./Search";

export default {
  components: { Search },
  props: {
    course: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      searchTerm: ""
    };
  },
  computed: {
    lessons() {
      return this.course.lessons.filter(
        item =>
          item.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0
      );
    }
  },
  methods: {
    duration(lesson) {
      if (!lesson.duration) return "";
      let [h, m, s] = lesson.duration.split(":");
      if (h === "00") {
        return `${m}:${s}`;
      } else {
        return lesson.duration;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.lesson-lessons-list {
  @media screen and (min-width: 992px) {
    width: 30%;
    min-width: 300px;
    min-height: calc(100vh - 56px);
    max-height: calc(100vh - 56px);
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 56px;

    .desktop-scroll {
      flex: 1 1 auto;
      overflow-y: scroll;
    }
  }

  .card {
    border-right: none;
    border-left: none;
    background: transparent;
    text-align: left;
    font-size: 14px;
    background: #fafafa;
    border: none;
    font-weight: bold;
  }

  a:hover {
    background: rgba(100, 100, 100, 0.05);
  }

  .circle {
    min-width: 24px;
    min-height: 24px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 16px;
      height: 16px;
    }
  }
  .check {
    background: #04c56b;
  }
  .play-current {
    border: 1px solid #ef4d57;
    display: none;
  }
  .play-next {
    border: 1px solid #c8d6e5;
  }

  .router-link-exact-active {
    .play-current {
      display: flex;
    }
    .play-next {
      display: none;
    }
  }
}
</style>
