<template>
  <action-dispatcher
    action="courses/getDetails"
    :parameters="$route.params.id"
    ref="coursesDispatcher"
    class="view-lesson d-flex justify-content-center"
  >
    <lessons-list class="order-lg-2" :course="course" />

    <div v-if="lesson" class="container-fluid px-lg-5 py-5 order-lg-1">
      <h3 class="font-weight-bold mb-2">{{ lesson.name }}</h3>
      <div class="text-secondary mb-4">{{ course.title }}</div>
      <div class="lesson-description" v-html="lesson.description"></div>

      <video-player
        v-if="lesson.stream_url"
        :lesson="lesson"
        class="mb-4"
        @save-progress="saveProgress"
      />
      <iframe
        class="border-0 w-100"
        v-else-if="archive"
        :src="archive"
      ></iframe>

      <div v-if="lesson.quiz">
        <quiz v-if="lesson.quiz.id" :id="lesson.quiz.id" />
      </div>

      <div
        class="d-flex flex-wrap justify-content-between align-items-center mb-5"
      >
        <div>
          <h6 v-if="lesson.archives.length">
            Material Complementar 💼
          </h6>
          <div v-for="(lesson, i) in lesson.archives" :key="i">
            <audio-player
              v-if="lesson.type === 'audio'"
              class="my-3"
              :lesson="lesson"
              @save-progress="saveProgress"
            />
            <a
              v-else-if="lesson.type === 'pdf'"
              class="btn btn-material text-danger mb-n2"
              target="_blank"
              :href="lesson.file"
            >
              DOWNLOAD PDF 📁
            </a>
          </div>
          <div>
            <b-spinner v-if="loadingFinish" variant="danger" />
            <button
              v-else
              class="btn px-0 text-dark d-flex align-items-center"
              @click="toggleFinished"
            >
              <i
                :class="{
                  'text-secondary icon-circle-thin mr-2': !finishedLesson,
                  'text-success icon-check-circle mr-2': finishedLesson
                }"
              ></i>
              <span v-if="finishedLesson">Aula finalizada</span>
              <span v-else>Marcar como finalizada</span>
            </button>
          </div>
        </div>
        <router-link
          v-if="nextLesson"
          class="btn btn-danger px-4 d-flex align-items-center"
          :to="{ name: 'lesson', params: { lesson: nextLesson.id } }"
        >
          Próxima aula <i class="icon-arrow-right ml-2"></i>
        </router-link>
      </div>
    </div>
  </action-dispatcher>
</template>

<script>
import LessonsList from "../components/lesson/LessonsList";
import VideoPlayer from "../components/lesson/VideoPlayer";
import AudioPlayer from "../components/lesson/AudioPlayer";
import Quiz from "../components/lesson/Quiz";

import { mapState } from "vuex";
import get from "lodash/get";

export default {
  components: { LessonsList, VideoPlayer, Quiz, AudioPlayer },
  data() {
    return {
      loadingFinish: false
    };
  },
  computed: {
    ...mapState("courses", ["course"]),
    archive() {
      return get(this.lesson, "archives[0].file");
    },
    lesson() {
      if (!this.course.lessons) {
        return null;
      }
      return this.course.lessons.find(
        lesson => lesson.id === parseInt(this.$route.params.lesson)
      );
    },
    nextLesson() {
      for (let i = 0; i < this.course.lessons.length; i++) {
        if (this.course.lessons[i].id === parseInt(this.$route.params.lesson)) {
          return this.course.lessons[i + 1];
        }
      }
      return null;
    },
    finishedLesson() {
      return (
        this.lesson && this.lesson.progress && this.lesson.progress.finished_at
      );
    }
  },
  methods: {
    toggleFinished() {
      this.saveProgress({ finished: !this.finishedLesson, duration: 0 }, true);
    },
    saveProgress({ finished, duration }, refresh = false) {
      if (refresh) {
        this.loadingFinish = true;
      }
      this.$store
        .dispatch("courses/setProgress", {
          lesson: this.lesson.id,
          stopped_at: parseInt(duration),
          finished_at: finished
        })
        .then(() => {
          if (refresh) {
            this.$store
              .dispatch("courses/getDetails", this.$route.params.id)
              .finally(() => {
                this.loadingFinish = false;
              });
          }
        });
    },
    downloadMaterial() {}
  }
};
</script>

<style lang="scss" scoped>
.view-lesson {
  min-height: calc(100vh - 56px);

  @media screen and (max-width: 991px) {
    flex-direction: column-reverse;
  }

  .course-description {
    font-size: 18px;
  }

  .lesson-description,
  .course-description {
    color: #555;
  }

  iframe {
    height: 500px;
  }

  .btn-material {
    transition: 0.4s;
    &:hover {
      filter: brightness(60%);
    }
  }
  .course-card {
    .card-img-top {
      height: 0;
      padding-bottom: 55%;
      background-size: cover;
      background-position-x: center;
      background-position-y: center;
    }
  }

  a {
    line-height: 34px;
  }

  button {
    i {
      font-size: 22px;
    }
  }

  // Quiz
}
</style>
